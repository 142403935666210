<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>
                Auction  
              </th>
              <th>
                Status
              </th>
              <th>
                Rules
              </th>
              <th>
                Leave
              </th>
            </tr>
          </thead>           
          <tbody>
            <tr v-for="auction in auctions">
              <td>
                <router-link :to="{name: 'show-auction', params: {id: auction.id}}">
                  {{auction.name}}
                </router-link>
                <br>
                <span class="small">
                  {{auction.start_at}}  
                </span>               
              </td>
              <td>{{auction.status}}</td>
              <td>
                <a href="#" class="rules_modal_link" :data-rules="auction.rules" :data-rules-header="auction.name + 'Rules'">Rules</a>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>  
    </div> 
  </div>
  
</template>

<script>
  export default {
    props: ['auctions'],
    name: 'auction-list'
  }
</script>