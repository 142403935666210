import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import NewAuction from '../components/NewAuction.vue'
import Index from '../components/Index.vue'

export default new VueRouter({
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
    },
    {
      path:'/auctions/new',
      name:'new-auction',
      component: NewAuction
    },
    {
      path:'/auctions/:id',
      name:'show-auction',
      component: NewAuction
    }
    
  ],
});