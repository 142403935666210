<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h3>Sharing</h3>
        <table class="table table-striped table-bordered table-condensed" v-if="current_user">
          <thead>
          <tr>
            <th>Owner</th>
            <th>Percent</th>
            <th>Cost</th>
            <th>Winnings</th>
            <th v-show="item.winner_id === current_user.id"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="share in shares" :key="share.id">
              <td>{{share.user_name}}</td>
              <td>{{share.percentage | percent(3, 1)}}</td>
              <td>{{share.cost | currency}}</td>
              <td>{{ share.winnings | currency}}</td>
              <td v-show="item.winner_id === current_user.id"><button class="btn btn-danger btn-xs" @click="remove(share.id)">Remove</button> </td>
            </tr>
            <tr>
              <td>{{item.winner}} (owner)</td>
              <td>{{ownerPercentage  | percent(3, 1)}}</td>
              <td>{{ownerCost | currency}}</td>
              <td>{{ ownerWinnings | currency}}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <form class="form-inline" v-if="current_user && current_user.id === item.winner_id">
      <select class="form-control" v-model="userId">
        <option v-for="user in users" :key="user.id" :value="user.id">{{user.name}}</option>
      </select>
      <div class="form-group">
        <input type="number" class="form-control" id="percentage" placeholder="Percentage" v-model="percentage">
      </div>
      <div class="form-group">
        <input type="number" class="form-control" id="cost" placeholder="Cost" v-model="cost">
      </div>
      <button class="btn btn-primary" @click="add">Add Share</button>
      <span v-show="error" class="danger red">{{error}}</span>
    </form>  
  </div>

</template>

<script>

import Models from "../resources";

export default {
  props: ['auction-id','item-id'],
  components: {},
  data () {
    return {
      auction: null,
      item: {},
      loaded: false,
      users: [],
      userId: null,
      percentage: null,
      cost: null,
      shares: [],
      current_user: null,
      error: null
    }
  },
  created () {
    Models.Auction.get({id: this.auctionId}).then(response => {
      this.loaded = true
      this.auction = response.data
      this.users = this.auction.users
    })
    Models.Item.get({ auction_id: this.auctionId, id: this.itemId }).then(response => {
      this.item = response.data
      this.shares = response.data.item_user_shares
    })
    this.$http.get('/api/users/me').then((resp) => {
      this.current_user = resp.data
    })
  },
  methods: {
    add() {
      Models.Item.add_share({ auction_id: this.auctionId, id: this.itemId }, {percentage: this.percentage, user_id: this.userId, cost: this.cost}).then((response) => {
        this.shares = response.data.item_user_shares
        this.cost = null
        this.percentage = null
        this.userId = null
      }).catch((error) => {
        console.log(error.data.error)
        this.error = error.data.error
      })
    },
    remove(id) {
      if(confirm("Are you sure?")){
        Models.Item.remove_share({ auction_id: this.auctionId, item_id: this.itemId, id: id }).then((response) => {
          this.shares = response.data.item_user_shares
        })  
      }
      
    }
  },
  computed: {
    ownerPercentage() {
      const total = this.shares.reduce((acc, s) => {
        return acc + parseFloat(s.percentage)
      }, 0.0)
      return 100.0 - total
    },
    ownerCost() {
      const total = this.shares.reduce((acc, s) => {
        return acc + parseFloat(s.cost || 0)
      }, 0.0)
      return this.item.price - total
    },
    ownerWinnings() {
      const total = this.shares.reduce((acc, s) => {
        return acc + parseFloat(s.percentage)
      }, 0.0)
      const ownerPercentage = 100.0 - total
      if(this.auction === null) return
      return ((this.item.percent_of_purse / 100) * parseFloat(this.auction.purse) * (ownerPercentage / 100))
    }
  }
}
</script>