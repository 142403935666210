<template>
    <div class="row">
        <div class="col-md-8">
            <h3> Auction Payouts </h3>
            <table class="table table-bordered table-striped table-condensed">
                <thead>
                <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Percentage</th>
                    <th>Amount</th>
                    <th>Winners</th>
                    <th></th>
                </tr>
                </thead>
                
                <draggable tag="tbody" v-model="payouts" @change="updateSort">
                  <auction-payout v-for="payout in payouts"
                                  :payout="payout"
                                  :auction-id="auctionId"
                                  :auction="auction"
                                  :key="payout.id"
                                  v-on:editPayout="editPayout"
                                  v-on:payoutDeleted="removePayout" >
  
                  </auction-payout>
                </draggable>  
                
                <tfoot>
                <tr>
                    <td colspan="2">
                        <b>
                            Total:
                        </b>
                    </td>
                    <td>
                        {{totalPercentage}}
                    </td>
                    <td>
                        {{totalAmount | currency}}
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                </tfoot>
            </table>
        </div>
        <div class="col-md-4" v-if="allowNew">
            <div >
                <form class="form" v-if="loaded">
                    <div class="form-group">
                        <label for="newPayoutName" class="control-label">Name</label>
                        <input type="text" class="form-control" id="newPayoutName" placeholder="Name" v-model="newPayout.name">
                    </div>
                    <div class="form-group">
                        <label for="newPayoutPercentage" class="control-label">Percentage</label>
                        <input type="text" class="form-control" id="newPayoutPercentage" placeholder="Percentage" v-model="newPayout.percentage">
                    </div>

                    <!--<div class="form-group">-->
                        <!--<label for="newPayoutAmount" class="control-label">Amount</label>-->
                        <!--<input type="text" class="form-control" id="newPayoutAmount" placeholder="Amount" v-model="newPayout.amount">-->
                    <!--</div>-->

                    <a class="btn btn-primary" @click="addPayout">Add Payout</a>
                </form>
            </div>
                
        </div> 
        <div class="col-md-4" v-show="!allowNew">
            <form class="form" v-if="loaded">
                <div class="form-group">
                    <label for="currentPayoutName" class="control-label">Percentage</label>
                    <input type="text" class="form-control" id="currentPayoutName" placeholder="Name" v-model="currentPayout.name">
                </div>
                <div class="form-group">
                    <label for="currentPayoutPercentage" class="control-label">Percentage</label>
                    <input type="text" class="form-control" id="currentPayoutPercentage" placeholder="Percentage" v-model="currentPayout.percentage">
                </div>

                <!--<div class="form-group">-->
                    <!--<label for="currentPayoutAmount" class="control-label">Amount</label>-->
                    <!--<input type="text" class="form-control" id="currentPayoutAmount" placeholder="Amount" v-model="currentPayout.amount">-->
                <!--</div>-->
                <b>
                    Winners
                </b>
                <ul>
                    <li v-for="(payout,index) in currentPayout.item_payouts">
                        {{payout.item.name}}
                        (<a class="danger" @click="removeWinner(index)">remove</a>)
                    </li>
                </ul>
                <multiselect 
                             :options="options" 
                             placeholder="Add Item Winner" 
                             label="name" 
                             track-by="id" 
                             @select="addWinner"
                             :allow-empty="true"
                             :reset-after="true"
                ></multiselect>
                
                <br>
                <br>
                <a class="btn btn-primary" @click="savePayout">Save Payout</a>
                <a class="btn btn-default" @click="clear">Cancel</a>
                <a @click="allowNew = false" v-show="allowNew">hide</a>
            </form>
        </div>
    </div>
    
    
</template>

<script>
  import Models from '../resources'
  import Multiselect from 'vue-multiselect'
  import AuctionPayout from './AuctionPayout.vue'
  import draggable from 'vuedraggable'
  
  export default {
    name: 'auction-payouts',
    components: { AuctionPayout, Multiselect, draggable },
    props: ['auction-id'],
    data(){
      return {
        payouts: [],
        allowNew: true,
        loaded: false,
        newPayout: {name: null, percentage: null, amount: null},
        value: null,
        options: [
        ],
        currentPayout: {},
        auction: null,
        dragging: false
      }
    },
    computed: {
      totalPercentage() {
        return _.reduce(this.payouts, (a, p) => {
          return a + parseFloat(p.percentage || 0)
        }, 0);
      },
      totalAmount() {
        return _.reduce(this.payouts, (a, p) => {
          return a + parseFloat(this.auction.purse * p.percentage * 0.01)
        }, 0);
      }
    
    
    },
    created(){
      Models.Auction.get({id: this.auctionId}).then(response => {
        this.payouts = response.body.auction_payouts
        this.sort()
        this.loaded = true
        this.auction = response.body
      })
      Models.Item.get({auction_id: this.auctionId}).then(response => {
        this.options = response.data
      })
      
    },
    mounted(){
      
    },
    methods: {
      addPayout(){
        
        Models.AuctionPayout.save({auction_id: this.auctionId}, {auction_payout: this.newPayout}).then(response => {
          this.payouts.push(response.body)
          this.sort()
          this.clear()
        })
      },
      removePayout(payoutId){
        Models.AuctionPayout.delete({auction_id: this.auctionId, id: payoutId}).then(() => {
          this.payouts = _.reject(this.payouts, function(payout){ return payout.id == payoutId; });
          this.sort()
          this.allowNew = true
        })
      },
      clear(){
        this.newPayout = {name: null, percentage: null, amount: null, auction_payout_id: null}
        this.allowNew = true
      },
      editPayout(payout){
        this.allowNew = false;
        this.currentPayout = payout
      },
      savePayout(){
        Models.AuctionPayout.update({auction_id: this.auctionId, id: this.currentPayout.id}, {auction_payout: this.currentPayout}).then((response)=>{
          let i = _.findIndex(this.payouts, (value) => {return value.id == this.currentPayout.id})
          this.payouts.splice(i, 1)
          this.payouts.push(response.data)
          this.allowNew = true;
          this.sort()
        })
    
      },
      updateSort() {
        Models.AuctionPayout.update_order({ auction_id: this.auctionId, id: this.currentPayout.id }, {auction_payouts: this.payouts.map((p) => p.id)}).then((response)=>{
          console.log('Sort Updated',response)
        })
      },
      sort(){
        console.log("Before Sorted", this.payouts)
        this.payouts = _.sortBy(this.payouts, (item) => {return parseInt(item.position)})
        console.log("After Sorted", this.payouts)
      },
      removeWinner(winner){
        this.currentPayout.item_payouts.splice(winner, 1)
//        this.currentPayout.item_payouts = _.filter(this.currentPayout.item_payouts, (item) => {return item.id != winner.id})
      },
      addWinner(item, id){
        this.currentPayout.item_payouts.push({item: item})
      }
        
    }
  }
</script>