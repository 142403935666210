<template>
  <div>
    <h2>
      Get Started
      <router-link :to="{name: 'new-auction'}" class="btn btn-primary">Create an Auction</router-link>
    </h2>
    
    <h4>Completed Auctions</h4>
    <auction-list :auctions="currentAuctions"></auction-list>
  </div>
  
</template>

<script>
  import AuctionList from './AuctionList.vue'
  
  export default {
    components: {AuctionList},
    data () {
      return {
        currentAuctions: []
      }
    },
    created(){
      Models.Auction.get({id: 'current_auctions'}).then( (response) => {
        this.currentAuctions = response.data
      })
    }
  }
</script>
