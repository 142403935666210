import Vue from "vue"
import VueResource from 'vue-resource';

Vue.use(VueResource);

const Models = {}
Models.AuctionPayout = Vue.resource('/api/auctions{/auction_id}/auction_payouts{/id}', {}, { 'update_order': { method: 'POST', url: '/api/auctions{/auction_id}/auction_payouts{/id}/update_order'}})
Models.ItemPayout = Vue.resource('/api/auctions{/auction_id}/items{/item_id}/item_payouts{/id}')
Models.Auction = Vue.resource('/api/auctions{/id}')
Models.User = Vue.resource('/api/users{/id}', {}, {
  me: { method: 'GET', url: '/api/users/me' }
})
Models.ShareProposal = Vue.resource('/api/auctions{/auction_id}/share_proposals{/id}')
Models.Item = Vue.resource('/api/auctions{/auction_id}/items{/id}', {}, {
  add_share: { method: 'POST', url: '/api/auctions{/auction_id}/items{/id}/add_share' },
  remove_share: { method: 'DELETE', url: '/api/auctions{/auction_id}/items{/item_id}/remove_share{/id}' },
  user_items: { method: 'GET', url: '/api/auctions{/auction_id}/items/user_items' }
})

export default Models 