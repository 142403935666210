import Vue from "vue"
import Vue2Filters from 'vue2-filters'
import Models from './resources'
import App from './components/App.vue'
import router from './routes'
import ItemPayoutList from './components/ItemPayoutList.vue'
import AuctionPayouts from './components/AuctionPayouts.vue'
import ItemSplit from './components/ItemSplit.vue'
import Marketplace from './components/Marketplace.vue'
import * as VueCookie from 'vue-cookie'

Vue.use(VueCookie);
Vue.use(Vue2Filters)

export const eventBus = new Vue({
  methods:{
    counter(num) {
      this.$emit('addNum', num);
    }
  }
});

window.Models = Models
Vue.http.headers.common['Authorization'] = Vue.cookie.get('token');

var element = document.getElementById("app");
if(typeof(element) != 'undefined' && element != null) {
  new Vue({
    el: '#app',
    router,
    render: h => h(App)
  })
}

//Item Payouts
element = document.getElementById("item-payouts-app");
if(typeof(element) != 'undefined' && element != null) {
  new Vue({
    components: {ItemPayoutList},
    el: "#item-payouts-app",
    data: {
      auction: null
    },
    
    created(){
      Models.Auction.get({id: $('#item-payouts-app').data('auction-id')}).then(response => {
        this.auction = response.data;
        eventBus.$emit('auction-loaded')
      })
    }
  })
}
// Auction Payouts
element = document.getElementById("auction-payouts-app");
if(typeof(element) != 'undefined' && element != null) {
  new Vue({
    components: {AuctionPayouts},
    el: "#auction-payouts-app",
    data: {
    }
  })
}

element = document.getElementById("item-split-app");
if(typeof(element) != 'undefined' && element != null) {
  new Vue({
    components: { ItemSplit },
    el: "#item-split-app",
    data: {
    }
  })
}

element = document.getElementById("marketplace-app");
if(typeof(element) != 'undefined' && element != null) {
  new Vue({
    components: { Marketplace },
    el: "#marketplace-app",
    data: {
    }
  })
}