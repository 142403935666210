<template>
    <tr class="form-inline">
        <td class="center">
          <span class="glyphicon glyphicon-move"></span>
        </td>
        <td >
            {{payout.name}}
        </td>
        <td>
            {{payout.percentage}}
        </td>
        <td>
            {{itemPayout(payout.percentage) | currency}}
        </td>
        <td>
            <ul class="list-unstyled">
                <li v-for="item in payout.item_payouts" v-text="item.item.name"></li>
            </ul>
        </td>
        <td>
            <a class="btn btn-primary btn-xs" @click="editPayout" >
                Edit
            </a>
            <a class="btn btn-danger btn-xs" @click="removeItem" >
                Delete
            </a>
        </td>
    </tr>
</template>

<script>

  export default {
    name: 'auction-payout',
    data(){
      return {
      }
    },
    props: ['payout','auction-id','auction'],
    methods: {
      removeItem(){
        if(confirm("Are you sure?")){
          this.$emit('payoutDeleted', this.payout.id)  
        }
      },
      editPayout(){
        this.$emit('editPayout', this.payout)
      },
      itemPayout(percent){
        return this.auction.purse * percent * 0.01
      }
    }
  }
</script>
<style scoped>
 .glyphicon-move {
   cursor: move;
 }
 .center {
   text-align: center;
 }
</style>