<template>
    <tr>
        <td>{{payout.name}}</td>
        <td>{{payout.percentage}} %</td>
        <td>{{parseFloat(payout.amount_paid_out) | currency}}</td>
        <td>
            <a class="btn btn-danger btn-sm" @click="removeItem">
                Delete
            </a>
        </td>
    </tr>
</template>

<script>

export default {
  name: 'item-payout',
  props: ['payout', 'auction-id', 'item-id'],
  methods: {
    removeItem(){
      if(confirm("Are you sure?")){
        Models.ItemPayout.delete({auction_id: this.auctionId, item_id: this.itemId, id: this.payout.id}).then( () => {
          this.$emit('payoutDeleted', this.payout.id)
        })  
      }
    }
  }
}
</script>