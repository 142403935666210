<template>
    <div id="item-payouts">
        <h3>Item Payouts</h3>
        <table class="table table-bordered table-striped">
            <thead>
            <tr>
                <th>Name</th>
                <th>Percentage</th>
                <th>Amount</th>
            </tr>
            </thead>
            <tbody>
                <item-payout v-for="payout in payouts" :payout="payout" :key="payout.id" :auction-id="auctionId" :item-id="itemId" v-on:payoutDeleted="removePayout"></item-payout>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <b>
                            Total:
                        </b>
                    </td>
                    <td>
                        {{totalPercentage}} %
                    </td>
                    <td>
                        {{totalAmount | currency}}
                    </td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
    </div>    
</template>

<script>
    import ItemPayout from './ItemPayout.vue'
    
    export default {
      name: 'item-payout-list',
      components: {ItemPayout, alert},
      props: ['item-id', 'auction-id'],
      data(){
        return {
          payouts: [],
          newItem: {name: null, percentage: null, amount: null, auction_payout_id: null},
          payout_options: [],
          loaded: false,
          allowNew: false,
          auction: null
        }
      },
      created(){
        Models.Auction.get({id: this.auctionId}).then(response => {
          this.auction = response.data;
          this.payout_options = this.auction.auction_payouts;
          
          Models.ItemPayout.get({auction_id: this.auctionId, item_id: this.itemId}).then((response) => {
            this.payouts = response.body;
            this.loaded = true
          })
        })
      },
      methods: {
        removePayout(id){
          this.payouts = _.reject(this.payouts, function(payout){ return payout.id == id; });
        },
        clear(){
          this.newItem = {name: null, percentage: null, amount: null, auction_payout_id: null}
        }
      },
      computed: {
        totalPercentage() {
          return _.reduce(this.payouts, (a, p) => {
            return a + parseFloat(p.percentage || 0.0)
          }, 0.0);
        },
        totalAmount() {
          return _.reduce(this.payouts, (a, p) => {
            return a + parseFloat(p.amount_paid_out)
          }, 0.0);
        }
        
          
      }

      
    }
</script>