<template>
  <div>
    <router-view></router-view>
  </div>
  
</template>

<script>
  import ItemPayoutList from './ItemPayoutList.vue'
  import AuctionPayouts from './AuctionPayouts.vue'

  export default {
    components: {AuctionPayouts, ItemPayoutList},
    data () {
      return {
        derek: "Derek"
      }
    }
  }
</script>
