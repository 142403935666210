<template>
  <div>
    <h3>Items For Sale</h3>
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th>Item</th>
          <th>% for Sale</th>
          <th>Price</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="currentUser">
        <tr v-for="proposal in availableProposals()" :key="proposal.id">
          <td>{{proposal.item_name}}</td>
          <td>{{proposal.remaining_percentage | percent(2,1)}}</td>
          <td>{{proposal.remaining_cost | currency("$", 0)}}</td>
          <td>
            <button class="btn btn-primary btn-xs" v-show="currentUser.id !== proposal.user_id" @click="loadProposal(proposal)">View</button>
            <button class="btn btn-danger btn-xs" v-show="currentUser.id === proposal.user_id" @click="removeProposal(proposal)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row" v-if="currentProposal">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">Proposal Details</h3>
          </div>
          <div class="panel-body">
            <div id="acceptProposalForm">
              <form>
                <p>
                  <b>Item:</b>
                  {{currentProposal.item_name}}
                  <br>
                  <b>Original %:</b>
                  {{currentProposal.percentage}}%
                  <b>Remaining %:</b>
                  {{currentProposal.remaining_percentage}}%
                </p>  
                <div class="form-group">
                  <label for="percentage">Percentage</label>
                  <input type="number" class="form-control" id="percentage" placeholder="Percentage" :max="currentProposal.remaining_percentage" min="1" step="1" v-model="newProposal.percentage">
                  <p class="help-block text-danger" v-if="newProposal.percentage > currentProposal.remaining_percentage">Too High</p>
                  <p class="help-block">Percentage to accept up to {{currentProposal.remaining_percentage}}%</p>
                </div>
                <div class="form-group">
                  <label>Price:</label>
                  {{proposalCost | currency("$", 2)}}
                </div>
                <button v-show="currentProposal.remaining_percentage > 0" class="btn btn-primary" @click="acceptProposal" :disabled="newProposal.percentage > currentProposal.remaining_percentage">Accept</button>
              </form>
            </div>            
          </div>
        </div>
      </div>
    </div>
    <h3>Purchases</h3>
    <table class="table table-bordered table-striped">
      <thead>
      <tr>
        <th>Item</th>
        <th>% Sold</th>
        <th>Price</th>
      </tr>
      </thead>
      <tbody v-if="currentUser">
        <tr v-for="proposal in closedProposals()" :key="proposal.id">
          <td>{{proposal.item_name}}</td>
          <td>{{proposal.percentage | percent(2,1)}}</td>
          <td>{{proposal.cost | currency("$", 0)}}</td>
        </tr>
      </tbody>
    </table>
    <h3>Your Private Offers</h3>
    <table class="table table-bordered table-striped">
      <thead>
      <tr>
        <th>Item</th>
        <th>% for Sale</th>
        <th>Price</th>
        <th>From</th>
        <th>State</th>
        <th></th>
      </tr>
      </thead>
      <tbody v-if="currentUser">
      <tr v-for="proposal in myOffers()" :key="proposal.id">
        <td>{{proposal.item_name}}</td>
        <td>{{proposal.remaining_percentage | percent(2,1)}}</td>
        <td>{{proposal.remaining_cost | currency("$", 0)}}</td>
        <td><span v-show="currentUser.id !== proposal.user_id">{{proposal.user.name}}</span></td>
        <td>
          <span v-show="currentUser.id === proposal.user_id && proposal.remaining_percentage === 0">Accepted</span>
          <span v-show="currentUser.id === proposal.user_id && proposal.remaining_percentage > 0 && proposal.remaining_percentage < proposal.percentage">Partially Accepted</span>
        </td>
        <td>
          <button class="btn btn-primary btn-xs" v-show="currentUser.id !== proposal.user_id" @click="loadPrivateProposal(proposal)">View</button>
          <button class="btn btn-danger btn-xs" v-show="currentUser.id === proposal.user_id && proposal.remaining_percentage > 0" @click="removeProposal(proposal)">Delete</button>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="row" v-if="currentPrivateProposal">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">Proposal Details</h3>
          </div>
          <div class="panel-body">
            <div id="acceptPrivateProposalForm">
              <form>
                <p>
                  <b>Item:</b>
                  {{currentPrivateProposal.item_name}}
                  <b>
                    Paid:
                  </b>
                  <span>
                    {{currentPrivateProposal.amount_paid | currency("$", 0)}}  
                  </span>
                  <br>
                  <b>Original %:</b>
                  {{currentPrivateProposal.percentage}}%
                  <b>Remaining %:</b>
                  {{currentPrivateProposal.remaining_percentage}}%
                </p>
                <div class="form-group">
                  <label for="percentage">Percentage</label>
                  <input type="number" class="form-control" id="privatePercentage" placeholder="Percentage" :max="currentPrivateProposal.remaining_percentage" min="1" step="1" v-model="newPrivateProposal.percentage">
                  <p class="help-block text-danger" v-if="newPrivateProposal.percentage > currentPrivateProposal.remaining_percentage">Too High</p>
                  <p class="help-block">Percentage to accept up to {{currentPrivateProposal.remaining_percentage}}%</p>
                </div>
                <div class="form-group">
                  <label>Price:</label>
                  {{privateProposalCost | currency("$", 2)}}
                </div>
                <button v-show="currentPrivateProposal.remaining_percentage > 0" class="btn btn-primary" @click="acceptPrivateProposal" :disabled="newPrivateProposal.percentage > currentPrivateProposal.remaining_percentage">Accept</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h3>Sell Item</h3>
    <div id="marketplace-sell-item-form" v-if="currentUser">
      <form class="form-inline">
        <select class="form-control" v-model="itemId">
          <option v-for="item in userItems" :key="item.id" :value="item.id">{{item.name}}</option>
        </select>
        <div class="form-group">
          <input type="number" class="form-control" placeholder="Percentage" v-model="percentage">
        </div>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Cost" v-model="cost">
        </div>
        <button class="btn btn-primary" @click="createProposal">Propose</button>
      </form>
    </div>
    <h3>Offer to Buy Item</h3>
    <div id="marketplace-buy-item-form" v-if="currentUser">
      <form class="form-inline">
        <select class="form-control" v-model="buyItemId">
          <option v-for="item in unownedItems()" :key="item.id" :value="item.id">{{item.name}}</option>
        </select>
        <div class="form-group">
          <input type="number" class="form-control" placeholder="Percentage" v-model="buyPercentage">
        </div>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Cost" v-model="buyPrice">
        </div>
        <button class="btn btn-primary" @click="createOfferToBuy">Offer To Buy</button>
      </form>
    </div>
  </div>
</template>

<script>

import Models from "../resources";

export default {
  name: 'marketplace',
  props: ['auction-id'],
  data(){
    return {
      userItems: [],
      auctionItems: [],
      itemId: null,
      buyItemId: null,
      percentage: null,
      cost: null,
      buyPercentage: null,
      buyPrice: null,
      proposals: [], 
      currentUser: {},
      currentProposal: null,
      currentPrivateProposal: null,
      newPrivateProposal: null,
      newProposal: null
    }
  },
  created() {
    Models.Item.user_items({ auction_id: this.auctionId }).then((response) => {
      this.userItems = response.data  
    })
    Models.Item.get({ auction_id: this.auctionId }).then((response) => {
      this.auctionItems = response.data
    })
    Models.ShareProposal.get({ auction_id: this.auctionId }).then((response) => {
      response.data.forEach((proposal) => {
        proposal.percentage = parseFloat(proposal.percentage)
        proposal.remaining_percentage = parseFloat(proposal.remaining_percentage)
        proposal.remaining_cost = parseFloat(proposal.remaining_cost)
      })
      this.proposals = response.data
    })
    Models.User.me().then((res) => {
      this.currentUser = res.data
    })
  },
  methods: {
    closedProposals() {
      return this.proposals.filter((p) => p.remaining_percentage <= 0)
    },
    availableProposals() {
      return this.proposals.filter((p) => p.remaining_percentage > 0 && p.proposal_type === 'sell')
    },
    myOffers() {
      return this.proposals.filter((p) => p.proposal_type === 'buy')
    },
    unownedItems() {
      return this.auctionItems.filter((i) => i.winner_id !== this.currentUser.id)
    },
    createProposal() {
      Models.ShareProposal.save({ auction_id: this.auctionId }, { share_proposal: {item_id: this.itemId, cost: this.cost, percentage: this.percentage}}).then((response) => {
        this.proposals.push(response.data)  
        this.percentage = null
        this.cost = null
        this.itemId = null
      })
    },
    createOfferToBuy() {
      Models.ShareProposal.save({ auction_id: this.auctionId }, { share_proposal: {item_id: this.buyItemId, cost: this.buyPrice, percentage: this.buyPercentage}}).then((response) => {
        this.proposals.push(response.data)
        this.buyPercentage = null
        this.buyPrice = null
        this.buyItemId = null
      })
    },
    removeItem(){
      if(confirm("Are you sure?")){
        Models.ItemPayout.delete({auction_id: this.auctionId, item_id: this.itemId, id: this.payout.id}).then( () => {
          this.$emit('payoutDeleted', this.payout.id)
        })
      }
    },
    loadProposal(proposal){
      this.currentProposal = proposal
      this.newProposal = Object.assign({}, proposal)
      this.newProposal.percentage = proposal.remaining_percentage
    },
    loadPrivateProposal(proposal){
      this.currentPrivateProposal = proposal
      this.newPrivateProposal = Object.assign({}, proposal)
      this.newPrivateProposal.percentage = proposal.remaining_percentage
    },
    acceptProposal() {
      Models.ShareProposal.update({ auction_id: this.auctionId, id: this.newProposal.id }, {percentage: this.newProposal.percentage}).then(() => {
        Models.ShareProposal.get({ auction_id: this.auctionId }).then((response) => {
          this.proposals = response.data
          this.newProposal = null
          this.currentProposal = null
        })
      })  
    },
    acceptPrivateProposal() {
      Models.ShareProposal.update({ auction_id: this.auctionId, id: this.newPrivateProposal.id }, {percentage: this.newPrivateProposal.percentage}).then(() => {
        Models.ShareProposal.get({ auction_id: this.auctionId }).then((response) => {
          this.proposals = response.data
          this.newPrivateProposal = null
          this.currentPrivateProposal = null
        })
      })
    },
    removeProposal(proposal) {
      if(confirm("Are you sure?")){
        Models.ShareProposal.delete({ auction_id: this.auctionId, id: proposal.id }).then((response) => {
          console.log(response.data)
          Models.ShareProposal.get({ auction_id: this.auctionId }).then((response) => {
            this.proposals = response.data
          })
        })
      }
    }
  },
  computed: {
    proposalCost() {
      return (this.newProposal.percentage / this.currentProposal.percentage) * this.currentProposal.cost
    },
    privateProposalCost() {
      return (this.newPrivateProposal.percentage / this.currentPrivateProposal.percentage) * this.currentPrivateProposal.cost
    }
  }
}

</script>